<template>
  <div class="app">
    <div class="center-content">
      <h1 :style="{ backgroundImage: gradientStyle }">{{ name }}</h1>
      <button @click="goToDashboard" class="dashboard-button">Go to Dashboard</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      name: "Coming Soon!",
      gradientStyle: "",
    };
  },
  mounted() {
    this.animateText();
  },
  methods: {
    animateText() {
      let r = 255;
      let g = 0;
      let b = 150;
      let direction = 1;
      const step = 8; 

      setInterval(() => {
        if (r === 255 && g < 255 && b === 150) {
          g += step * direction;
        } else if (g === 255 && r > 0) {
          r -= step * direction;
        } else if (r === 0 && b < 255) {
          b += step * direction;
        } else if (b === 255 && g > 0) {
          g -= step * direction;
        } else if (g === 0 && r < 255) {
          r += step * direction;
        } else if (r === 255 && b > 150) {
          b -= step * direction;
        }

        if (r < 0) r = 0;
        if (g < 0) g = 0;
        if (b < 0) b = 0;
        if (r > 255) r = 255;
        if (g > 255) g = 255;
        if (b > 255) b = 255;

        this.gradientStyle = `linear-gradient(90deg, rgb(${r}, ${g}, ${b}), rgb(${b}, ${g}, ${r}), rgb(${g}, ${b}, ${r}))`;
      }, 50); 
    },
    goToDashboard() {
      window.location.href = "https://dashboard.ekaunter.xyz/";
    },
  }
};
</script>

<style scoped>
body, html {
  height: 100%;
  margin: 0;
  font-family: 'Arial', sans-serif;
  background: #000; 
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.app {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 2;
}

.center-content {
  z-index: 2;
}

.center-content h1 {
  font-size: 5em;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  transition: background-image 0.5s ease-in-out; 
}

.center-content p {
  font-size: 1.5em;
  margin-top: -20px;
}

.heart {
  color: red;
  animation: heartbeat 1s infinite;
}

@keyframes heartbeat {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.2); }
}

.dashboard-button {
  margin-top: 30px;
  padding: 10px 25px;
  font-size: 1.5em;
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  letter-spacing: 0.05em;
}
.dashboard-button:hover {
  background: linear-gradient(135deg, #2575fc, #6a11cb);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  transform: scale(1.05);
}
</style>
